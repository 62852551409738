import axios from "axios"
const useAxios= axios.create()

// set base url
//useAxios.defaults.baseURL = 'http://127.0.0.1:8000/api/acgame/'
useAxios.defaults.baseURL = 'https://api.alcaponewrap.com/api/acgame/'

// axios basic auth @todo disable on production
/* useAxios.defaults.auth = {
     username: 'geraldo',
     password: 'dannemann2'
 }*/

// interceptor to handle auth-token on request
useAxios.interceptors.request.use(config => {

    config.params = config.params || {}
    config.params['xACGameToken'] = localStorage.getItem('xACGameToken')
    return config
});

// interceptor to handle auth-token on response
useAxios.interceptors.response.use(response => {
    const token = response.data.xACGameToken || localStorage.getItem('xACGameToken')
    localStorage.setItem('xACGameToken', token)
    return response
})

export {useAxios}